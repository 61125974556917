import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, Link, useLocation } from 'react-router-dom';
import Slider from 'react-slick';
import configUrl from '../configUrl';
import Header from './Header';
import Footer from './Footer';

// Import CSS Slick jika tidak menggunakan file CSS terpisah
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';

const VideoCategory = () => {
  const location = useLocation();
  const { category } = useParams();
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 480);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const appliedStyles = isMobile ? mobileStyles : styles;

  const fetchVideos = async () => {
    try {
      const response = await axios.get(
        `${configUrl.beBaseUrl}/api/articles/category/video`
      );
      // console.log(response.data, 'ini response video');
      setVideos(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching videos:', error);
      setError('Failed to fetch videos.');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchVideos();
  }, [category]);

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  if (loading) return <div>Loading videos...</div>;
  if (error) return <div style={styles.error}>{error}</div>;
  if (videos.length === 0) return <div>No videos found for {category}.</div>;

  return (
    <div>
      {location.pathname !== '/' && <Header />}
      <div style={appliedStyles.container}>
        <div style={appliedStyles.titleContainer}>
          <div style={appliedStyles.underline}></div>
          <h3 style={appliedStyles.title}>VIDEO {category}</h3>
        </div>
  
        <div 
          style={{ 
            ...appliedStyles.mainVideo, 
            ...(isMobile ? appliedStyles.mainVideoMobile : {}) 
          }}
        >
          <Link to={`/articles/${videos[0].id}/${videos[0].slug}`} style={{ textDecoration: 'none' }}>
            <img
              src={`${configUrl.beBaseUrl}${videos[0].image_url}`}
              alt={videos[0].title}
              style={{ 
                ...appliedStyles.mainThumbnail, 
                ...(isMobile ? appliedStyles.mainThumbnailMobile : {}) 
              }}
            />
            <h2 
              style={{ 
                ...appliedStyles.mainTitle, 
                ...(isMobile ? appliedStyles.mainTitleMobile : {}) 
              }}
            >
              {videos[0].title}
            </h2>
          </Link>
        </div>

        {/* Add the "Tonton Video Lainnya" title here */}
        <h4 style={appliedStyles.otherVideosTitle}>Tonton Video Lainnya:</h4>
  
        <Slider {...sliderSettings} style={appliedStyles.carousel}>
          {videos.slice(1).map((video) => (
            <div key={video.id} style={appliedStyles.carouselItem}>
              <Link
                to={`/articles/${video.id}/${video.slug}`}
                style={{ ...appliedStyles.videoLink, textDecoration: 'none' }}
              >
                <div style={appliedStyles.videoContainer}>
                  <img
                    src={`${configUrl.beBaseUrl}${video.image_url}`}
                    alt={video.title}
                    style={appliedStyles.carouselThumbnail}
                  />
                  <p style={appliedStyles.carouselTitle}>
                    {video.title.length > 50 ? `${video.title.slice(0, 50)}...` : video.title}
                  </p>
                </div>
              </Link>
            </div>
          ))}
        </Slider>
      </div>
      {location.pathname !== '/' && <Footer />}
    </div>
  );  
};

const styles = {
  container: {
    maxWidth: '1200px',
    padding: '0px 100px',
    margin: '0 auto',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  underline: {
    width: '6px',
    height: '24px',
    backgroundColor: '#005694',
    marginRight: '10px',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
  },
  mainVideo: {
    position: 'relative',
    marginBottom: '20px',
    overflow: 'hidden', // Pastikan konten tidak keluar
  },
  mainVideoMobile: {
    width: '87vw', // Lebar penuh
    margin: '0', // Hilangkan margin
    borderRadius: '0', // Hilangkan border-radius
  },
  otherVideosTitle: {
    fontSize: '15px',
    fontWeight: 'bold',
    marginTop: '20px',
    marginBottom: '10px',
    textAlign: 'left',
  },
  mainThumbnail: {
    width: '100%',
    height: '400px',
    objectFit: 'cover',
    borderRadius: '10px',
  },
  mainThumbnailMobile: {
    height: '250px', // Atur tinggi lebih pendek di mobile
    borderRadius: '0', // Hilangkan border-radius
  },
  mainTitle: {
    position: 'absolute',
    bottom: '20px',
    left: '7px',
    color: '#fff',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    padding: '10px',
    borderRadius: '5px',
    fontSize: '24px',
  },
  mainTitleMobile: {
    fontSize: '18px', // Ukuran lebih kecil di mobile
    bottom: '10px',
    left: '5px',
    padding: '8px',
  },
  carousel: {
    marginTop: '20px',
  },
  carouselItem: {
    padding: '10px',
    borderRadius: '10px',
    overflow: 'hidden',
  },
  videoContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '10px',
  },
  carouselThumbnail: {
    width: '100px',
    height: '80px',
    objectFit: 'cover',
    borderRadius: '8px',
  },
  carouselTitle: {
    fontSize: '13px',
    textAlign: 'left',
    color: '#000',
  },
  videoLink: {
    display: 'block',
  },
  error: {
    color: 'red',
    textAlign: 'center',
  },
};

// Mobile Styles
const mobileStyles = {
  container: {
    maxWidth: '1200px',
    marginTop: '-20px',
    padding: '20px',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  underline: {
    width: '6px',
    height: '24px',
    backgroundColor: '#005694',
    marginRight: '10px',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
  },
  mainVideo: {
    position: 'relative',
    marginBottom: '20px',
    overflow: 'hidden', // Pastikan konten tidak keluar
  },
  mainVideoMobile: {
    width: '87vw', // Lebar penuh
    margin: '0', // Hilangkan margin
    borderRadius: '0', // Hilangkan border-radius
  },
  otherVideosTitle: {
    fontSize: '15px',
    fontWeight: 'bold',
    marginTop: '20px',
    marginBottom: '10px',
    textAlign: 'left',
  },
  mainThumbnail: {
    width: '100%',
    height: '400px',
    objectFit: 'cover',
    borderRadius: '10px',
  },
  mainThumbnailMobile: {
    height: '250px', // Atur tinggi lebih pendek di mobile
    borderRadius: '0', // Hilangkan border-radius
  },
  mainTitle: {
    position: 'absolute',
    bottom: '20px',
    left: '7px',
    color: '#fff',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    padding: '10px',
    borderRadius: '5px',
    fontSize: '24px',
  },
  mainTitleMobile: {
    fontSize: '18px', // Ukuran lebih kecil di mobile
    bottom: '10px',
    left: '5px',
    padding: '8px',
  },
  carousel: {
    marginTop: '20px',
  },
  carouselItem: {
    padding: '10px',
    borderRadius: '10px',
    overflow: 'hidden',
  },
  videoContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '10px',
  },
  carouselThumbnail: {
    width: '100px',
    height: '80px',
    objectFit: 'cover',
    borderRadius: '8px',
  },
  carouselTitle: {
    fontSize: '16px',
    textAlign: 'left',
    color: '#000',
  },
  videoLink: {
    display: 'block',
  },
  error: {
    color: 'red',
    textAlign: 'center',
  },
};

export default VideoCategory;
