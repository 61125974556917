import React from 'react';
import SearchBar from '../components/SearchBar';

const SearchPage = () => {
  return (
    <div>
      <SearchBar />
    </div>
  );
};

export default SearchPage;
