import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import configUrl from '../configUrl';
import ArticleCard from '../dashboard/article-card';
import Header from './Header';
import Footer from './Footer';

const CategoryArticles = () => {
  const { slug } = useParams();
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get(`${configUrl.beBaseUrl}/api/categories/${slug}/articles`);
        setArticles(response.data);
      } catch (error) {
        console.error('Error fetching articles:', error.response?.data || error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, [slug]);

  if (loading) {
    const styles = {
        container: {
            width: '146px',
            height: '49px',
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            margin: 'auto',
            opacity: 0,
            animation: 'fadeIn 1.15s forwards',
        },
        wrap: (delay) => ({
            position: 'absolute',
            animation: `translate 1150ms infinite ease-in-out alternate, zindex 2300ms infinite ease-in-out`,
            animationDelay: delay,
        }),
        ball: (color, delay) => ({
            width: '49px',
            height: '49px',
            boxShadow: '0 -6.25px 0 rgba(0,0,0,0.15) inset',
            backgroundColor: color,
            borderRadius: '50%',
            animation: 'scale 1150ms infinite ease-in-out alternate',
            animationDelay: delay,
            transform: 'scale(0.5)',
            border: '2px solid black',
        }),
        ballAfter: {
            content: '""',
            width: '49px',
            height: '13px',
            background: 'rgb(238,238,238)',
            position: 'absolute',
            top: '68px',
            borderRadius: '50%',
        },
        keyframes: `
            @keyframes translate {
                100% { transform: translateX(97px); }
            }
            @keyframes scale {
                100% { transform: scale(1); }
            }
            @keyframes zindex {
                25% { z-index: 1; }
                75% { z-index: -1; }
            }
            @keyframes fadeIn {
                100% { opacity: 1; }
            }
        `,
    };

    return (
        <div>
            <style>{styles.keyframes}</style>
            <div style={styles.container}>
                <div style={styles.wrap('0ms')}>
                    <div style={styles.ball('rgb(227,116,107)', '-575ms')}></div>
                </div>
                <div style={styles.wrap('-1150ms')}>
                    <div style={styles.ball('rgb(57,123,249)', '-1725ms')}></div>
                </div>
                <div style={styles.wrap('-1725ms')}>
                    <div style={styles.ball('rgb(244,180,0)', '-2300ms')}></div>
                </div>
                <div style={styles.wrap('-2875ms')}>
                    <div style={styles.ball('rgb(15,157,88)', '-3450ms')}></div>
                </div>
            </div>
        </div>
    );
}

  return (
    <div style={styles.pageContainer}>
      <Header />
      <div style={styles.contentContainer}>
        <h1 style={styles.header}>Artikel dalam Kategori "{slug}"</h1>
        <div style={styles.articleContainer}>
          {articles.map((article, index) => (
            <ArticleCard
              key={article.id}
              article={article}
              index={index}
              showControls={false}
            />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

const styles = {
  // pageContainer: {
  //   backgroundColor: '#f4f4f4',
  //   minHeight: '100vh',
  //   padding: '20px 0',
  // },
  contentContainer: {
    maxWidth: '800px',
    margin: '20px auto',
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    fontFamily: 'Arial, sans-serif',
  },
  loading: {
    textAlign: 'center',
    fontSize: '18px',
    marginTop: '50px',
  },
  header: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '20px',
    borderBottom: '2px solid #ddd',
    paddingBottom: '10px',
  },
  articleContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px', // Space between articles
  },
};

export default CategoryArticles;
