import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import About from './page/About';
import Slider from './components/Slider';
import Login from './components/auth/Login';
import SearchBar from './components/SearchBar';
import SearchPage from './page/SearchPage';
import CategoriesList from './components/Categories';
import SearchResults from './page/SearchResults';
import Header from './components/Header';
import BeritaTerkini from './components/BeritaTerkini'; 
import Dashboard from './dashboard/dashboard';
import ArticleList from './components/ArticleList';
import VideoCategory from './components/VideoCategory';
import ArticleDetail from './page/ArticleDetail';
import { AuthProvider } from './utils/AuthContext';
import GoogleLoginButton from './components/GoogleLoginButton';
import IndexDashboard from './dashboard';
import SidebarDashboard from './components/SidebarDashboard';
import EditArticle from './dashboard/edit-article';
import PopularArticles from './components/PopularArticles';
import CreateArticle from './page/CreateArticle';
import ShareButtons from './components/ShareButtons';
import PrivateRoute from './components/auth/PrivateRoute';
import CategoryArticles from './components/CategoryArticles';
import CategoryDropdown from './components/CategoryDropdown';
import PasswordReset from './components/auth/PasswordReset';
import TopTags from './components/TopTagsArtikel';
import TagArticlesPage from '../src/page/TagArticlesPage';
import ArtikelBawah from './components/googleTrend';
import TopTagsLink from './components/TopTagsLink';
import VideoDetail from './components/VideoDetail';
import TermsOfUse from './page/TermsOfUse';
import PedomanMediaSiber from './page/PedomanMediaSiber';
import Help from './page/Help'; // Keep this import
import PrivacyPolicy from './page/PrivacyPolicy';
import Contact from './page/Contact';
import Footer from './components/Footer';

function Home() {
  const [styles, setStyles] = useState({
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '20px',
      flexWrap: 'wrap',
    },
    leftColumn: {
      width: '70%',
      marginRight: '60px',
    },
    rightColumn: {
      width: '20%',
    },
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setStyles({
          container: {
            flexDirection: 'column',
            padding: '10px',
          },
          leftColumn: {
            width: '100%',
            marginRight: '0',
            marginBottom: '20px',
            fontSize: '1.2em',
          },
          rightColumn: {
            width: '100%',
            fontSize: '1.2em',
          },
        });
      } else {
        setStyles({
          container: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '20px',
            flexWrap: 'wrap',
          },
          leftColumn: {
            width: '70%',
            marginRight: '20px',
            fontSize: '1em',
          },
          rightColumn: {
            width: '25%',
            fontSize: '1em',
          },
        });
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <AuthProvider>
    <div>
      <Header />
      <Slider />
      <div style={styles.container}>
        <div style={styles.leftColumn}>
          <BeritaTerkini />
          <PopularArticles />
          <VideoCategory />
        </div>
        <div style={styles.rightColumn}>
          <TopTags />
          <TopTagsLink />
        </div>
      </div>
      <ArtikelBawah />
      <Footer />
    </div>
  </AuthProvider>
  );
}

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/login" element={<Login />} />
          <Route path="/passwordreset" element={<PasswordReset />} />
          <Route path="/searchbar" element={<SearchBar />} />
          <Route path="/search" element={<SearchPage />} />
          <Route path="/BeritaTerkini" element={<BeritaTerkini />} />
          <Route path="/PopularArticles" element={<PopularArticles />} />
          <Route path="/search-results" element={<SearchResults />} />
          <Route path="/articlelist" element={<ArticleList />} />
          <Route path="/articles/:id/:slug" element={<ArticleDetail />} />
          <Route path="/kategori/:slug" element={<CategoryArticles />} />
          <Route path="/category" element={<CategoriesList />} />
          <Route path="/videos/category/:category" element={<VideoCategory />} />
          <Route path="/videos/:id" element={<VideoDetail />} />
          <Route path="/categorydropdown" element={<CategoryDropdown />} />
          <Route path="/sharebuttons" element={<ShareButtons />} />
          <Route path="/tags/:tagId" element={<TagArticlesPage />} />
          <Route path="/TermsOfUse" element={<TermsOfUse />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/index" element={<IndexDashboard />} />
          <Route path="/Help" element={<Help />} />
          <Route path="/PedomanMediaSiber" element={<PedomanMediaSiber />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/footer" element={<Footer />} />
          
          <Route element={<PrivateRoute />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/create-article" element={<CreateArticle />} />
            <Route path="/edit-article/:id" element={<EditArticle />} />
          </Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
