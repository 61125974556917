import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

function About() {
  return (
    <div>
      <Header />
    <div style={styles.container}>
      <div style={styles.logoContainer}>
        <img src="/image/kartikanews-logo.png" alt="kartikanews-logo" style={styles.logoImage} />
      </div>
      <h3 style={styles.subheading}>Mengangkat Suara Nusantara</h3>
      <p style={styles.paragraph}>
        kartikalidya.com adalah situs media online di Indonesia. Dengan semangat yang sama, kami hadir untuk menyajikan informasi dari seluruh penjuru Tanah Air melalui jaringan yang luas dan kuat.
        kartikalidya.com adalah bagian dari transformasi digital di Indonesia. Kami berkomitmen untuk menghadirkan berita dan informasi terkini dari Sabang sampai Merauke, menjangkau masyarakat luas dengan berbagai perspektif lokal.
        kartikalidya.com mengusung tagline <strong>Mengangkat Suara Nusantara</strong>, dengan misi untuk memperkuat ikatan lokal dan mengedepankan tanggung jawab bersama dalam menyuarakan Indonesia.
      </p>
    </div>
    <Footer />
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '40px 20px',
    backgroundColor: '#f9f9f9',
    lineHeight: '1.6',
  },
  logoContainer: {
    margin: '0px 0', // Memberi jarak antara logo dengan teks di atas dan bawah
  },
  logoImage: {
    width: '250px',
    height: 'auto',
  },
  heading: {
    fontSize: '36px',
    color: '#005694',
    marginBottom: '20px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  subheading: {
    fontSize: '24px',
    color: '#1D78B6',
    marginBottom: '20px',
  },
  paragraph: {
    maxWidth: '800px',
    fontSize: '18px',
    color: '#333',
    textAlign: 'justify',
    padding: '0 15px',
  },
};

export default About;
