import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SidebarDashboard from "../components/SidebarDashboard";
import ChartArticlesPopular from "../components/ChartArticlesPopular";
import Footer from '../components/Footer';
import axios from 'axios';
import configUrl from '../configUrl';

const IndexDashboard = () => {
    const [categories, setCategories] = useState([]);
    const navigate = useNavigate(); // Hook for navigation

    useEffect(() => {
        // Check if user is authenticated
        const isAuthenticated = localStorage.getItem('token'); // Assuming you store the auth token in localStorage

        if (!isAuthenticated) {
            navigate('/index'); // Redirect to login if not authenticated
        }
        
        const fetchCategories = async () => {
            try {
                const response = await axios.get(`${configUrl.beBaseUrl}/api/categories`);
                setCategories(response.data);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };
        fetchCategories();
    }, [navigate]); // Add navigate to dependency array

    return (
        <div>
            <div style={styles.container}>
                <div style={styles.sidebar}>
                    <SidebarDashboard categories={categories} /> {/* Pass categories as props */}
                </div>
                <div style={styles.content}>
                    <ChartArticlesPopular />
                </div>
            </div>
            {/* <Footer /> */}
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row', // Default to row for desktop
        width: '100%',
    },
    sidebar: {
        width: '23%', // Sidebar width for desktop
    },
    content: {
        width: '80%', // Content width for desktop
    },
    '@media (max-width: 768px)': { // Adjust the breakpoint as needed
        container: {
            flexDirection: 'column', // Stack vertically for mobile
        },
        sidebar: {
            width: '100%', // Full width for sidebar on mobile
        },
        content: {
            width: '100%', // Full width for content on mobile
        },
    },
};

export default IndexDashboard;
