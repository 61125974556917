import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import axios from 'axios';
import configUrl from '../../src/configUrl';
import Header from './Header';
import Footer from './Footer';

const SearchBar = () => {
    const [query, setQuery] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate(); 

    const handleSearchChange = (e) => {
        setQuery(e.target.value);
        setMessage('');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!query.trim()) {
            setMessage('Masukkan kata kunci pencarian.');
            return;
        }

        try {
            const response = await axios.get(`${configUrl.beBaseUrl}/api/search`, {
                params: { query }
            });

            const results = response.data;
            navigate(`/search-results?query=${encodeURIComponent(query)}&results=${encodeURIComponent(JSON.stringify(results))}`);
        } catch (error) {
            console.error('Error searching articles:', error);
            alert('Terjadi kesalahan saat mencari. Silakan coba lagi.');
        }
    };

    return (
        <div>
            <Header />
            <div style={styles.container}>
                <div style={styles.logoContainer}> 
                    <img src="/image/kartikanews-logo.png" alt="kartikanews-logo" style={styles.logoImage} />
                </div>
                <form onSubmit={handleSubmit}>
                    <div style={styles.searchBox}>
                        <input
                            type="text"
                            placeholder="Cari artikel..."
                            value={query}
                            onChange={handleSearchChange}
                            style={styles.searchInput}
                        />
                        <button type="submit" style={styles.submitButton}>Search</button>
                    </div>
                </form>
                {message && <div style={styles.message}>{message}</div>}
            </div>
            <Footer />
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '75vh',
        backgroundColor: '#fff',
    },
    logoContainer: {
        marginBottom: '20px',
    },
    logoImage: {
        width: '320px',
        height: 'auto',
    },
    searchBox: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#ffffff',
        borderRadius: '5px',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        padding: '10px',
    },
    searchInput: {
        flex: '1',
        padding: '10px',
        border: 'none',
        outline: 'none',
        fontSize: '16px',
    },
    submitButton: {
        padding: '10px 15px',
        backgroundColor: '#007BFF',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    },
    message: {
        marginTop: '10px',
        color: 'red',
        fontSize: '16px',
    },
};

export default SearchBar;
