import React, { useEffect, useState } from 'react';
import configUrl from '../configUrl';

const GoogleTrend = () => {
    const [rssItems, setRssItems] = useState([]);
    const [error, setError] = useState(null);
    const [showAll, setShowAll] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);
   
    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 480);
        
        // Set isMobile saat komponen pertama kali di-render
        handleResize();
    
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    
      const appliedStyles = isMobile ? mobileStyles : styles;

    useEffect(() => {
        const url = `${configUrl.beBaseUrl}/api/trends`;

        fetch(url)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Tidak dapat mengambil data RSS');
                }
                return response.text();
            })
            .then((data) => {
                const parser = new DOMParser();
                const xmlDoc = parser.parseFromString(data, 'application/xml');

                const items = xmlDoc.querySelectorAll('item');
                const rssData = Array.from(items).flatMap((item) => {
                    const newsItems = item.querySelectorAll('ht\\:news_item, news_item');
                    return Array.from(newsItems).map((news) => ({
                        title: news.querySelector('ht\\:news_item_title, news_item_title')?.textContent.trim() || 'No Title',
                        url: news.querySelector('ht\\:news_item_url, news_item_url')?.textContent.trim() || '#',
                        picture: news.querySelector('ht\\:news_item_picture, news_item_picture')?.textContent.trim() || '',
                        source: news.querySelector('ht\\:news_item_source, news_item_source')?.textContent.trim() || 'Unknown Source',
                        snippet: news.querySelector('ht\\:news_item_snippet, news_item_snippet')?.textContent.trim() || '',
                        pubDate: item.querySelector('pubDate')?.textContent.trim() || '',
                    }));
                });
                setRssItems(rssData);
            })
            .catch((error) => setError(error.message));
    }, []);

    const handleShowMore = () => setShowAll(true);

    return (
        <div className="google-trend-articles" style={appliedStyles.container}>
            <div style={appliedStyles.titleContainer}>
                <div style={appliedStyles.underline}></div>
                <h3 style={appliedStyles.trendingTitle}>RECOMMENDED</h3>
                <span style={appliedStyles.moreText} onClick={handleShowMore}>
                    More
                </span>
            </div>
            {error ? (
                <p style={appliedStyles.error}>{error}</p>
            ) : (
                <div className="rss-feed" style={appliedStyles.articlesList}>
                    {(showAll ? rssItems : rssItems.slice(0, 4)).map((item, index) => (
                        <div key={index} className="card-tag" style={appliedStyles.card}>
                            <div style={appliedStyles.imageWrapper}>
                                {item.picture && (
                                    <img src={item.picture} alt={item.title} style={appliedStyles.image} />
                                )}
                            </div>
                            <div className="card-content" style={appliedStyles.cardContent}>
                                <a href={item.url} target="_blank" rel="noopener noreferrer" style={appliedStyles.titleLink}>
                                    <h3 style={appliedStyles.articleTitle}>
                                        {item.title.length > 50 ? `${item.title.slice(0, 50)}...` : item.title}
                                    </h3>
                                </a>
                                <p style={appliedStyles.snippet}>{item.snippet}</p>
                                <span style={appliedStyles.source}>{item.source} - {item.pubDate}</span>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

const styles = {
    container: {
        padding: '0px 110px',
        maxWidth: '1230px',
        margin: '0 auto',
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '20px',
    },
    underline: {
        width: '6px',
        height: '24px',
        backgroundColor: '#005694',
        marginRight: '10px',
    },
    trendingTitle: {
        fontSize: '24px',
        fontWeight: 'bold',
    },
    moreText: {
        marginLeft: 'auto',
        color: '#005694',
        cursor: 'pointer',
        fontWeight: 'bold',
    },
    articlesList: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '20px',
    },
    card: {
        width: 'calc(25% - 15px)',
        borderRadius: '10px',
        overflow: 'hidden',
        flexShrink: 0,
    },
    imageWrapper: {
        height: '100px',
        overflow: 'hidden',
        width: '90%',
        margin: '0 auto',
        borderRadius: '5px',
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    titleLink: {
        fontSize: '10px',
        color: '#333',
        fontWeight: '500',
        textDecoration: 'none',
    },
    snippet: {
        fontSize: '10px',
        color: '#333',
    },
    source: {
        fontSize: '10px',
        color: '#aaa',
    },
    error: {
        color: 'red',
        textAlign: 'center',
    },
};
// Mobile Styles
const mobileStyles = {
    container: {
        padding: '0px 30px',
        maxWidth: '100%',
        margin: '0 auto',
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '20px',
    },
    underline: {
        width: '6px',
        height: '24px',
        backgroundColor: '#005694',
        marginRight: '10px',
    },
    trendingTitle: {
        fontSize: '24px',
        fontWeight: 'bold',
    },
    moreText: {
        marginLeft: 'auto',
        color: '#005694',
        cursor: 'pointer',
        fontWeight: 'bold',
    },
    articlesList: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '20px',
    },
    card: {
        width: 'calc(50% - 10px)', // Setengah dari lebar untuk menampilkan 2 kolom per baris
        borderRadius: '10px',
        overflow: 'hidden',
        flexShrink: 0,
    },
    imageWrapper: {
        height: '100px',
        overflow: 'hidden',
        width: '90%',
        margin: '0 auto',
        borderRadius: '5px',
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    titleLink: {
        fontSize: '10px',
        color: '#333',
        fontWeight: '500',
        textDecoration: 'none',
    },
    snippet: {
        fontSize: '10px',
        color: '#333',
    },
    source: {
        fontSize: '10px',
        color: '#aaa',
    },
    error: {
        color: 'red',
        textAlign: 'center',
    },
};

export default GoogleTrend;
