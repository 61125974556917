import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import configUrl from '../configUrl';
import Header from '../components/Header';
import Footer from '../components/Footer';

const TagArticlesPage = () => {
  const { tagId } = useParams();
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get(`${configUrl.beBaseUrl}/api/tag/${tagId}`);
        setArticles(response.data);
      } catch (error) {
        console.error('Error fetching articles by tag:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, [tagId]);

  if (loading) {
    const styles = {
        container: {
            width: '146px',
            height: '49px',
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            margin: 'auto',
            opacity: 0,
            animation: 'fadeIn 1.15s forwards',
        },
        wrap: (delay) => ({
            position: 'absolute',
            animation: `translate 1150ms infinite ease-in-out alternate, zindex 2300ms infinite ease-in-out`,
            animationDelay: delay,
        }),
        ball: (color, delay) => ({
            width: '49px',
            height: '49px',
            boxShadow: '0 -6.25px 0 rgba(0,0,0,0.15) inset',
            backgroundColor: color,
            borderRadius: '50%',
            animation: 'scale 1150ms infinite ease-in-out alternate',
            animationDelay: delay,
            transform: 'scale(0.5)',
            border: '2px solid black',
        }),
        ballAfter: {
            content: '""',
            width: '49px',
            height: '13px',
            background: 'rgb(238,238,238)',
            position: 'absolute',
            top: '68px',
            borderRadius: '50%',
        },
        keyframes: `
            @keyframes translate {
                100% { transform: translateX(97px); }
            }
            @keyframes scale {
                100% { transform: scale(1); }
            }
            @keyframes zindex {
                25% { z-index: 1; }
                75% { z-index: -1; }
            }
            @keyframes fadeIn {
                100% { opacity: 1; }
            }
        `,
    };

    return (
        <div>
            <style>{styles.keyframes}</style>
            <div style={styles.container}>
                <div style={styles.wrap('0ms')}>
                    <div style={styles.ball('rgb(227,116,107)', '-575ms')}></div>
                </div>
                <div style={styles.wrap('-1150ms')}>
                    <div style={styles.ball('rgb(57,123,249)', '-1725ms')}></div>
                </div>
                <div style={styles.wrap('-1725ms')}>
                    <div style={styles.ball('rgb(244,180,0)', '-2300ms')}></div>
                </div>
                <div style={styles.wrap('-2875ms')}>
                    <div style={styles.ball('rgb(15,157,88)', '-3450ms')}></div>
                </div>
            </div>
        </div>
    );
}

  return (
    <div>
      <Header />
    <div style={styles.wrapper}>
      <div style={styles.container}>
        <h1 style={styles.title}>Artikel dengan Tag: #{tagId}</h1>
        <ul style={styles.articleList}>
          {articles.map((article) => (
            <li key={article.id} style={styles.articleItem}>
              <div style={styles.articleHeader}>
                <h2 style={styles.articleTitle}>{article.title}</h2>
                {/* <span style={styles.articleDate}>
                  {new Date(article.created_at).toLocaleDateString()} */}
                {/* </span> */}
              </div>
              <p style={styles.articleBody}>
                {article.body.substring(0, 100)}...
              </p>
              <Link to={`/articles/${article.id}/${article.slug}`} style={styles.readMore}>
                Baca Selengkapnya
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
    <Footer />
    </div>
  );
};

// CSS in JS styles
const styles = {
  wrapper: {
    backgroundColor: '#f4f4f4', 
    minHeight: '100vh',
    padding: '20px 0',
  },
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    fontFamily: 'Arial, sans-serif',
  },
  title: {
    fontSize: '28px',
    fontWeight: 'bold',
    marginBottom: '20px',
    borderBottom: '2px solid #ddd',
    paddingBottom: '10px',
  },
  loading: {
    textAlign: 'center',
    marginTop: '50px',
    fontSize: '24px',
  },
  articleList: {
    listStyle: 'none',
    padding: 0,
  },
  articleItem: {
    borderBottom: '1px solid #ddd',
    padding: '15px 0',
    marginBottom: '10px',
  },
  articleHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  articleTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    margin: 0,
    textAlign: 'left',
  },
  articleBody: {
    fontSize: '16px',
    color: '#555',
    margin: '10px 0',
    textAlign: 'left',
  },
  readMore: {
    fontSize: '16px',
    color: '#007bff',
    textDecoration: 'none',
  },
};

export default TagArticlesPage;
