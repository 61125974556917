import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const TermsOfUse = () => {
  return (
    <div>
      <Header />
    <div style={styles.container}>
      <h1 style={styles.header}>TERMS OF USE</h1>
      <p style={styles.text}>
        Selamat datang di kartikalidya.com, situs berita yang Dipublikasikan oleh PT Indopersda Primamedia, 
        Divisi Koran Daerah Kompas Gramedia, Jakarta. Terms of Use berikut adalah ketentuan dalam penggunaan situs, 
        konten, layanan, dan fitur yang ada di Tribunnews.com. Term of Use ini juga berlaku sama untuk produk turunan dari situs ini.
      </p>
      <p style={styles.text}>
        Dengan mengakses dan menggunakan Tribunnews.com, berarti Anda telah memahami dan setuju untuk terikat dengan semua peraturan yang berlaku 
        di situs ini. Jika Anda tidak setuju untuk terikat dengan semua peraturan yang berlaku, silakan untuk tidak menggunakan situs ini.
      </p>
      
      <h2 style={styles.subHeader}>PERUBAHAN TERMS OF USE</h2>
      <p style={styles.text}>
        Pengelola dapat setiap saat mengganti, menambah, atau mengurangi Terms of Use ini. Anda terikat oleh setiap perubahan tersebut 
        dan karenanya secara berkala harus melihat halaman ini untuk memeriksa Terms of Use yang berlaku dan mengikat Anda.
      </p>

      <h2 style={styles.subHeader}>PENGUNJUNG YANG TERDAFTAR</h2>
      <p style={styles.text}>
        Beberapa layanan pada situs ini, seperti Tribunners (Citizen Reporter dan Citizen Article), hanya tersedia untuk pengunjung yang telah 
        mendaftar dan mengharuskan pengunjung untuk mendaftar dengan user ID untuk menggunakannya. Sebagai pengunjung yang terdaftar, 
        Anda diwajibkan untuk mengikuti segala peraturan penggunaan layanan tersebut.
      </p>
      <p style={styles.text}>
        Selain Pengunjung Terdaftar, pembaca juga dapat berkontribusi berita/tulisan, melalui beberapa cara yang telah ditentukan pengelola. 
        (Lihat HELP)
      </p>

      <h2 style={styles.subHeader}>EDITING DAN PUBLISH</h2>
      <p style={styles.text}>
        Proses editing dan publish atas tulisan yang Anda kirimkan, menjadi hak dan tanggung jawab pengelola media. Agar tulisan Anda bisa 
        dimuat, pastikan tulisan tidak mengandung unsur SARA, pornografi, dan semua hal yang tak pantas atau bertentangan dengan hukum yang berlaku.
      </p>
      <p style={styles.text}>
        Tulisan yang dipublikasikan di media ini, menjadi tanggung jawab penulis secara pribadi. Tribunnews.com tidak bertanggung jawab atas 
        persoalan hukum yang muncul atas tulisan yang dipublikasikan.
      </p>
    </div>
    <Footer />
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    maxWidth: '800px',
    margin: '0 auto',
    lineHeight: '1.6',
    fontFamily: 'Arial, sans-serif',
  },
  header: {
    fontSize: '28px',
    fontWeight: 'bold',
    marginBottom: '20px',
    textAlign: 'left',
  },
  subHeader: {
    fontSize: '22px',
    fontWeight: 'bold',
    marginTop: '20px',
    marginBottom: '10px',
    textAlign: 'left',
  },
  text: {
    fontSize: '16px',
    marginBottom: '10px',
    textAlign: 'left',
  },
};

export default TermsOfUse;
