import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import configUrl from '../../configUrl';

function PasswordReset() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        if (newPassword !== confirmPassword) {
            setError('Password baru dan konfirmasi tidak cocok.');
            return;
        }

        try {
            const response = await axios.post(`${configUrl.beBaseUrl}/api/reset-password`, {
                email,
                old_password: oldPassword,
                new_password: newPassword,
                new_password_confirmation: confirmPassword,
            });
            setSuccess('Password berhasil direset!');
            navigate('/login');
        } catch (err) {
            setError(err.response?.data?.error || 'Terjadi kesalahan saat mereset password.');
        }
    };

    return (
        <div style={styles.wrapper}>
            <form onSubmit={handleSubmit} style={styles.form}>
                <h2 style={styles.title}>Reset Password</h2>
                {error && <p style={styles.error}>{error}</p>}
                {success && <p style={styles.success}>{success}</p>}
                <div style={styles.inputGroup}>
                    <label>Email</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        style={styles.input}
                        required
                    />
                </div>
                <div style={styles.inputGroup}>
                    <label>Password Lama</label>
                    <input
                        type="password"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                        style={styles.input}
                        required
                    />
                </div>
                <div style={styles.inputGroup}>
                    <label>Password Baru</label>
                    <input
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        style={styles.input}
                        required
                    />
                </div>
                <div style={styles.inputGroup}>
                    <label>Konfirmasi Password</label>
                    <input
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        style={styles.input}
                        required
                    />
                </div>
                <button type="submit" style={styles.button}>
                    Reset Password
                </button>
                <div style={styles.linkContainer}>
                    <a href="/login" style={styles.link}>Kembali ke Login</a>
                </div>
            </form>
        </div>
    );
}

const styles = {
    wrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f5f5f5',
    },
    form: {
        width: '100%',
        maxWidth: '400px',
        padding: '20px',
        backgroundColor: '#fff',
        borderRadius: '10px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    },
    title: {
        textAlign: 'center',
        color: '#005694',
        marginBottom: '20px',
    },
    inputGroup: {
        marginBottom: '15px',
    },
    input: {
        width: '93%',
        padding: '10px',
        marginTop: '5px',
        borderRadius: '5px',
        border: '1px solid #ccc',
    },
    button: {
        width: '99%',
        padding: '10px',
        marginTop: '5px',
        borderRadius: '5px',
        backgroundColor: '#005694',
        color: '#fff',
        border: 'none',
    },
    linkContainer: {
        textAlign: 'center',
        marginTop: '15px',
    },
    link: {
        color: '#007bff',
        textDecoration: 'none',
    },
    error: {
        color: 'red',
        textAlign: 'center',
    },
    success: {
        color: 'green',
        textAlign: 'center',
    },
};

export default PasswordReset;
