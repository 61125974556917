import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import configUrl from '../configUrl';
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ArticleSlider = () => {
  const [articles, setArticles] = useState([]);
  const [latestArticles, setLatestArticles] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);

  // Styles
  const styles = {
    sliderContainer: {
      width: '95%',
      padding: '0',
      margin: '0 auto',
      position: 'relative',
    },
    itemSlider: {
      position: 'relative',
    },
    imageContainer: {
      position: 'relative',
      height: '380px',
    },
    headlineImage: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: '8px',
    },
    headlineOverlay: {
      position: 'absolute',
      bottom: '0',
      left: '0',
      right: '0',
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      padding: '10px',
      borderBottomLeftRadius: '8px',
      borderBottomRightRadius: '8px',
      color: '#fff',
    },
    headlineTitle: {
      fontSize: '16px',
      fontWeight: 'bold',
    },
    headlineTimestamp: {
      fontSize: '10px',
      marginTop: '3px',
    },
    articleCardSlider: {
      marginTop: '20px',
      display: 'flex',
      flexWrap: 'wrap',
    },
    articleCardItem: {
      flex: '0 0 48%',
      margin: '1%',
    },
    articleCardImage: {
      width: '100%',
      height: 'auto',
      borderRadius: '8px',
    },
    titleInCard: {
      display: 'block',
      fontSize: '12px',
      fontWeight: 'bold',
      marginTop: '8px',
      textTransform: 'capitalize',
      color: '#000',
    },
  };

  // Mobile Styles
  const mobileStyles = {
    sliderContainer: {
      width: '100%',
      padding: '0',
      margin: '0 auto',
      position: 'relative',
    },
    itemSlider: {
      position: 'relative',
    },
    imageContainer: {
      position: 'relative',
      height: '380px',
    },
    headlineImage: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: '8px',
    },
    headlineOverlay: {
      position: 'absolute',
      bottom: '0',
      left: '0',
      right: '0',
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      padding: '10px',
      borderBottomLeftRadius: '8px',
      borderBottomRightRadius: '8px',
      color: '#fff',
    },
    headlineTitle: {
      fontSize: '16px',
      fontWeight: 'bold',
    },
    headlineTimestamp: {
      fontSize: '10px',
      marginTop: '3px',
    },
    articleCardSlider: {
      marginTop: '20px',
      display: 'flex',
      flexWrap: 'wrap',
    },
    articleCardItem: {
      flex: '0 0 48%',
      margin: '1%',
    },
    articleCardImage: {
      width: '100%',
      height: 'auto',
      borderRadius: '8px',
    },
    titleInCard: {
      display: 'block',
      fontSize: '12px',
      fontWeight: 'bold',
      marginTop: '8px',
      textTransform: 'capitalize',
      color: '#000',
    },
  };

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 480);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const appliedStyles = isMobile ? mobileStyles : styles;

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const sliderResponse = await axios.get(`${configUrl.beBaseUrl}/api/articleSlider`);
        setArticles(sliderResponse.data);
      } catch (error) {
        console.error('Error fetching articles:', error);
      }
    };

    fetchArticles();
  }, []);

  const timeAgo = (dateString) => {
    const now = new Date();
    const articleDate = new Date(dateString);
    const secondsAgo = Math.floor((now - articleDate) / 1000);
    let interval = Math.floor(secondsAgo / 31536000);

    if (interval >= 1) {
      return `${interval} tahun yang lalu`;
    }
    interval = Math.floor(secondsAgo / 2592000);
    if (interval >= 1) {
      return `${interval} bulan yang lalu`;
    }
    interval = Math.floor(secondsAgo / 86400);
    if (interval >= 1) {
      return `${interval} hari yang lalu`;
    }
    interval = Math.floor(secondsAgo / 3600);
    if (interval >= 1) {
      return `${interval} jam yang lalu`;
    }
    interval = Math.floor(secondsAgo / 60);
    if (interval >= 1) {
      return `${interval} menit yang lalu`;
    }
    return 'baru saja';
  };

  const settings = {
    dots: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    infinite: articles.length > 1,
  };

  return (
    <div style={appliedStyles.sliderContainer}>
      <div className="article-slider">
        <Slider {...settings}>
          {articles.map(article => (
            <div key={article.id} style={appliedStyles.itemSlider}>
              <Link to={`/articles/${article.id}/${encodeURIComponent(article.slug)}`} className='headline-link'>
                <div style={appliedStyles.imageContainer}>
                  <img src={`${configUrl.beBaseUrl}${article.image_url}`} alt={article.title} style={appliedStyles.headlineImage} />
                  <div style={appliedStyles.headlineOverlay}>
                    <h4 style={appliedStyles.headlineTitle}>{article.title}</h4>
                    <span style={appliedStyles.headlineTimestamp}>{timeAgo(article.created_at)}</span>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </Slider>
      </div>
      <div style={appliedStyles.articleCardSlider}>
        {latestArticles.map(article => (
          <div key={article.id} style={appliedStyles.articleCardItem}>
            <Link to={`/articles/${article.id}/${encodeURIComponent(article.slug)}`} style={{ textDecoration: 'none' }}>
              <img src={`${configUrl.beBaseUrl}${article.image_url}`} alt={article.title} style={appliedStyles.articleCardImage} />
              <span style={appliedStyles.titleInCard}>{article.title}</span>
            </Link>
          </div>
        ))}
      </div>
      {/* CSS styles */}
      <style>
        {`
          .slick-slider {
            position: relative;
            display: block;
            box-sizing: border-box;
          }
          .slick-slide {
            display: block;
            float: left;
            height: 100%;
            min-height: 1px;
          }
          .slick-prev, .slick-next {
            font-size: 0;
            line-height: 0;
            color: transparent;
            border: none;
            background: transparent;
            cursor: pointer;
            position: absolute;
            top: 50%;
            width: 30px;
            height: 30px;
            margin-top: -15px;
            padding: 0;
            z-index: 1;
          }
          .slick-prev {
            left: 10px;
          }
          .slick-next {
            right: 10px;
          }
          .slick-prev:before, .slick-next:before {
            font-family: 'slick';
            font-size: 24px;
            color: black;
          }
          .slick-dots {
            bottom: 10px;
          }
          
          @media (min-width: 1024px) {
            .article-slider {
              padding: 40px 80px;
            }
          }

          @media (min-width: 768px) and (max-width: 1023px) {
            .article-slider {
              padding: 20px 40px;
            }
          }

          @media (max-width: 767px) {
            .article-slider {
              padding: 0;
            }
            .article-card-slider {
              margin: 0;
              padding: 0;
            }
          }
        `}
      </style>
    </div>
  );
};

export default ArticleSlider;
