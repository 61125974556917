import React from 'react';

const ShareButtons = ({ articleUrl, articleTitle }) => {
  // Function to check if the articleUrl is valid
  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };

  if (!isValidUrl(articleUrl)) {
    console.error("Invalid article URL provided:", articleUrl);
    return null;
  }

  const encodedUrl = encodeURIComponent(articleUrl);
  const encodedTitle = encodeURIComponent(articleTitle);

  const shareOnFacebook = () => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`, '_blank');
  };

  const shareOnTwitter = () => {
    window.open(`https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedTitle}`, '_blank');
  };

  const shareOnWhatsApp = () => {
    window.open(`https://api.whatsapp.com/send?text=${encodedTitle} - ${encodedUrl}`, '_blank');
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(articleUrl);
      alert('Tautan berhasil disalin ke clipboard!');
    } catch (error) {
      console.error('Gagal menyalin tautan:', error);
    }
  };

  return (
    <div style={styles.container}>
      <p style={styles.shareText}>Share Article on:</p>
      <div style={styles.shareButtonsContainer}>
        <button onClick={shareOnFacebook} style={{ ...styles.shareButton, backgroundColor: '#3b5998' }}>
          <i className="fab fa-facebook-f"></i>
        </button>
        <button onClick={shareOnTwitter} style={{ ...styles.shareButton, backgroundColor: '#1da1f2' }}>
          <i className="fab fa-twitter"></i>
        </button>
        <button onClick={shareOnWhatsApp} style={{ ...styles.shareButton, backgroundColor: '#25d366' }}>
          <i className="fab fa-whatsapp"></i>
        </button>
        <button onClick={copyToClipboard} style={{ ...styles.shareButton, backgroundColor: '#6c757d' }}>
          <i className="fas fa-link"></i>
        </button>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center', // Sejajar secara vertikal
  },
  shareText: {
    marginRight: '10px', // Jarak antara teks dan ikon
    fontSize: '16px',
    fontWeight: 'bold',
  },
  shareButtonsContainer: {
    display: 'flex',
    gap: '10px',
  },
  shareButton: {
    color: 'white',
    border: 'none',
    width: '40px',
    height: '40px',
    borderRadius: '50%', // Tombol bulat
    cursor: 'pointer',
    fontSize: '20px', // Ukuran ikon
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
};

export default ShareButtons;
