import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import configUrl from '../configUrl';
import Pagination from '../components/Pagination';
import SidebarDashboard from '../components/SidebarDashboard';
import { FaEdit } from 'react-icons/fa';

const Dashboard = () => {
  const [articles, setArticles] = useState([]);
  const [categories, setCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = 6;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchArticlesAndCategories = async () => {
      const token = localStorage.getItem('authTokenSitusNews');

      if (!token) {
        navigate('/login');
        return;
      }

      try {
        const articlesResponse = await axios.get(`${configUrl.beBaseUrl}/api/articles`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setArticles(articlesResponse.data);

        const categoriesResponse = await axios.get(`${configUrl.beBaseUrl}/api/categories`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCategories(categoriesResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error.response?.data || error.message);
      }
    };

    fetchArticlesAndCategories();
  }, [navigate]);

  const getCategoryName = (categoryId) => {
    const category = categories.find(cat => cat.id === categoryId);
    return category ? category.name : 'Tidak ada Kategori';
  };

  const handleEdit = (articleId) => {
    navigate(`/edit-article/${articleId}`);
  };

  const handleDelete = async (articleId) => {
    if (window.confirm('Yakin akan menghapus artikel ini ?')) {
      try {
        const token = localStorage.getItem('authTokenSitusNews');
        if (!token) {
          navigate('/login');
          return;
        }

        await axios.delete(`${configUrl.beBaseUrl}/api/articles/${articleId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // console.log(`Article with id ${articleId} deleted`);
        setArticles(articles.filter(article => article.id !== articleId));
      } catch (error) {
        console.error('Error deleting article:', error);
      }
    }
  };

  const handleSetHeadline = async (article) => {
    const token = localStorage.getItem('authTokenSitusNews');

    if (!token) {
      navigate('/login');
      return;
    }

    const isSlider = article.slider;

    try {
      await axios.post(`${configUrl.beBaseUrl}/api/articles/${article.id}/${isSlider ? 'unset-slider' : 'set-as-slider'}`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Update the article list to reflect the change
      setArticles((prevArticles) =>
        prevArticles.map((item) =>
          item.id === article.id ? { ...item, slider: !isSlider } : item
        )
      );

      // console.log(`Article with id ${article.id} set as slider: ${!isSlider}`);
    } catch (error) {
      console.error('Error setting article as slider headline:', error);
    }
  };

  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = articles.slice(indexOfFirstArticle, indexOfLastArticle);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <div style={{ display: 'flex', flex: 1 }}>
        <div style={{ width: '20%' }}><SidebarDashboard /></div>
        <div style={styles.articleList}>
          <h2 style={styles.title}>
            <FaEdit style={styles.icon} /> Post
          </h2>
          <table style={styles.table}>
            <thead>
              <tr>
                <th style={styles.th}>No</th>
                <th style={styles.th}>Image</th>
                <th style={styles.th}>Title</th>
                <th style={styles.th}>Category</th>
                <th style={styles.th}>Date</th>
                <th style={styles.th}>Actions</th>
              </tr>
            </thead>
            <tbody>
  {currentArticles.map((article, index) => (
    <tr key={article.id}>
      <td style={styles.td}>{index + 1}</td>
      <td style={styles.td}>
        <img
          src={`${configUrl.beBaseUrl}${article.image_url}`}
          alt={article.title}
          style={styles.img}
        />
      </td>
      <td style={styles.td}>
        <Link to={`/articles/${article.id}/${encodeURIComponent(article.slug)}`} style={styles.articleTitle}>
          {article.title}
        </Link>
      </td>
      <td style={styles.td}>{getCategoryName(article.category_id)}</td>
      <td style={styles.td}>
        {new Date(article.created_at).toLocaleDateString('id-ID', {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })}
      </td>
      <td style={styles.td}>
        <button style={styles.button} onClick={() => handleEdit(article.id)}>Edit</button>
        <button style={styles.button} onClick={() => handleDelete(article.id)}>Delete</button>
        <button style={styles.button} onClick={() => handleSetHeadline(article)}>
          {article.slider ? 'Unset Headline' : 'Set as Headline'}
        </button>
      </td>
    </tr>
  ))}
</tbody>
          </table>
          <Pagination 
            articlesPerPage={articlesPerPage} 
            totalArticles={articles.length} 
            paginate={paginate} 
            currentPage={currentPage} 
          />
        </div>
      </div>
    </div>
  );
};

const styles = {
  articleList: {
    padding: '20px',
    flex: 1,
    overflowY: 'auto',
    margin: '70px',
    width: '100%',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '20px',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    marginBottom: '20px',
  },
  th: {
    padding: '10px',
    textAlign: 'left',
    backgroundColor: '#f4f4f4',
    borderBottom: '2px solid #ddd',
  },
  td: {
    padding: '10px',
    textAlign: 'left',
    borderBottom: '1px solid #ddd',
  },
  img: {
    width: '80px',
    height: '60px',
    objectFit: 'cover',
    borderRadius: '5px',
  },
  icon: {
    fontSize: '20px',
  },
  articleTitle: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#007BFF',
    textDecoration: 'none',
  },
  button: {
    margin: '3px 5px',
    padding: '5px 10px',
    backgroundColor: '#005694',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

export default Dashboard;
