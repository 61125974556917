import React from 'react';

const Pagination = ({ articlesPerPage, totalArticles, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalArticles / articlesPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      <ul style={styles.pagination}>
        {pageNumbers.map(number => (
          <li key={number} style={currentPage === number ? styles.pageItemActive : styles.pageItem}>
            <button onClick={() => paginate(number)} style={styles.pageLink}>
              {number}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

const styles = {
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
    listStyleType: 'none',
    padding: 0,
  },
  pageItem: {
    margin: '0 5px',
  },
  pageLink: {
    padding: '6px 12px',
    backgroundColor: '#007BFF',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  pageItemActive: {
    backgroundColor: '#333',
  },
};

export default Pagination;