import React from 'react';
import { useLocation } from 'react-router-dom';
import configUrl from '../configUrl'; // Import your base URL config
import Header from '../components/Header';
import Footer from '../components/Footer';

const SearchResults = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search).get('query') || '';
  const resultsParam = new URLSearchParams(location.search).get('results');
  let results = [];
  try {
    results = resultsParam ? JSON.parse(resultsParam) : [];
  } catch (error) {
    console.error('Failed to parse results:', error);
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `Published: ${day}-${month}-${year}`;
  };

  return (
    <div>
      <Header />
    <div style={styles.wrapper}>
      <div style={styles.container}>
        <h1 style={styles.title}>Hasil Pencarian</h1>
        {query && <p style={styles.searchQuery}>Hasil pencarian untuk: <strong>{query}</strong></p>}
        
        {/* Container untuk membungkus hasil artikel */}
        <div style={styles.resultsContainer}>
          <ul style={styles.articleList}>
            {results.length > 0 ? (
              results.map(result => (
                <li key={result.id} style={styles.articleItem}>
                  <img
                    src={result.image_url ? `${configUrl.beBaseUrl}${result.image_url}` : '/image/default-placeholder.png'}
                    alt={result.title}
                    style={styles.img}
                  />
                  <div style={styles.articleContent}>
                    <a href={`/articles/${result.id}/${encodeURIComponent(result.slug)}`} style={styles.articleLink}>
                      {result.title}
                    </a>
                    <p style={styles.articleBody}>
                      {result.body.substring(0, 100)}...
                    </p>
                    <div style={styles.metaContainer}>
                      <span style={styles.articleDate}>{formatDate(result.created_at)}</span>
                      <span style={styles.articleViews}>Read: {result.views} x</span>
                    </div>
                  </div>
                </li>
              ))
            ) : (
              <p style={styles.noResults}>Tidak ada hasil yang ditemukan.</p>
            )}
          </ul>
        </div>
      </div>
    </div>
    <Footer />
    </div>
  );
};

// CSS in JS styles
const styles = {
  wrapper: {
    backgroundColor: '#f4f4f4',
    minHeight: '100vh',
    padding: '20px 0',
  },
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    fontFamily: 'Arial, sans-serif',
  },
  title: {
    fontSize: '28px',
    fontWeight: 'bold',
    marginBottom: '20px',
    borderBottom: '2px solid #ddd',
    paddingBottom: '10px',
  },
  searchQuery: {
    fontSize: '16px',
    marginBottom: '20px',
  },
  resultsContainer: {
    marginTop: '10px',
    padding: '10px',
    border: '1px solid #ddd',
    borderRadius: '8px',
  },
  articleList: {
    listStyle: 'none',
    padding: 0,
  },
  articleItem: {
    display: 'flex',
    alignItems: 'flex-start',
    borderBottom: '1px solid #ddd',
    padding: '15px 0',
    marginBottom: '10px',
  },
  img: {
    width: '100px',
    height: '70px',
    objectFit: 'cover',
    marginRight: '20px',
  },
  articleContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  articleLink: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#005694',
    textDecoration: 'none',
    marginBottom: '-11px',
    textAlign: 'left',
  },
  articleBody: {
    fontSize: '16px',
    color: '#555',
    marginBottom: 'px',
    textAlign: 'left',
  },
  metaContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  articleDate: {
    fontSize: '12px',
    color: '#666',
  },
  articleViews: {
    fontSize: '12px',
    fontWeight: 'bold',
    color: '#666',
  },
  noResults: {
    color: '#333',
    fontSize: '16px',
    textAlign: 'center',
  },
};

export default SearchResults;
