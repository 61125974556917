import React, { useEffect, useState } from 'react';
import axios from 'axios';
import configUrl from '../configUrl';
import SidebarDashboard from './SidebarDashboard';
import Pagination from '../components/Pagination'; // Import komponen pagination
import {FaFolder} from 'react-icons/fa';

const CategoriesList = () => {
    const [categories, setCategories] = useState([]);
    const [newCategory, setNewCategory] = useState('');
    const [editCategoryId, setEditCategoryId] = useState(null);
    const [editCategoryName, setEditCategoryName] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const categoriesPerPage = 10; // Jumlah kategori per halaman

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get(`${configUrl.beBaseUrl}/api/categories`, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                setCategories(response.data);
            } catch (error) {
                console.error('Error fetching categories:', error.response?.data || error.message);
            }
        };
        fetchCategories();
    }, []);

    const handleAddCategory = async () => {
        if (newCategory) {
            try {
                const token = localStorage.getItem('authTokenSitusNews');
                const response = await axios.post(`${configUrl.beBaseUrl}/api/categories`, 
                { name: newCategory }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                
                const newCat = response.data;
                setCategories([...categories, newCat]);
                setNewCategory('');
            } catch (error) {
                console.error('Error adding category:', error.response?.data || error.message);
            }
        }
    };

    const handleEditCategory = (categoryId, categoryName) => {
        setEditCategoryId(categoryId);
        setEditCategoryName(categoryName);
    };

    const handleUpdateCategory = async () => {
        if (editCategoryId && editCategoryName) {
            try {
                const token = localStorage.getItem('authTokenSitusNews');
                await axios.put(`${configUrl.beBaseUrl}/api/categories/${editCategoryId}`, 
                { name: editCategoryName }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                
                // Memperbarui kategori di state
                setCategories(categories.map(cat => 
                    cat.id === editCategoryId ? { ...cat, name: editCategoryName } : cat
                ));
                setEditCategoryId(null);
                setEditCategoryName('');
            } catch (error) {
                console.error('Error updating category:', error.response?.data || error.message);
            }
        }
    };

    const handleDeleteCategory = async (categoryId) => {
        try {
            const token = localStorage.getItem('authTokenSitusNews');
            await axios.delete(`${configUrl.beBaseUrl}/api/categories/${categoryId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            // Setelah menghapus, perbarui daftar kategori
            setCategories(categories.filter(category => category.id !== categoryId));
        } catch (error) {
            console.error('Error deleting category:', error.response?.data || error.message);
        }
    };

    // Logic Pagination
    const indexOfLastCategory = currentPage * categoriesPerPage;
    const indexOfFirstCategory = indexOfLastCategory - categoriesPerPage;
    const currentCategories = categories.slice(indexOfFirstCategory, indexOfLastCategory);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div style={{ display: 'flex' }}>
            <div style={{ width: '25%', marginBottom: '10px' }}>
                <SidebarDashboard />
            </div>
            <div style={{ width: '75%', padding: '20px' }}>
                <div>
                <h2 style={styles.title}>
                <FaFolder style={styles.icon} /> CategoriesList
                 </h2>
                {/* Input untuk New Category */}
                <div style={styles.inputContainer}>
                    <label htmlFor="newCategory" style={styles.label}>New Category:</label>
                    <input
                        type="text"
                        id="newCategory"
                        name="newCategory"
                        value={newCategory}
                        onChange={(e) => setNewCategory(e.target.value)}
                        placeholder="Enter new category"
                        style={styles.input}
                    />
                    <button type="button" onClick={handleAddCategory} style={styles.addButton}>Add Category</button>
                </div>
    
                {/* Tabel untuk Menampilkan Kategori */}
                <table style={styles.table}>
                    <thead>
                        <tr>
                            <th style={styles.tableHeader}>No</th>
                            <th style={styles.tableHeader}>Category Name</th>
                            <th style={styles.tableHeader}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentCategories.map((category, index) => (
                            <tr key={category.id}>
                                <td style={styles.tableCell}>{indexOfFirstCategory + index + 1}</td>
                                <td style={styles.tableCell}>
                                    {editCategoryId === category.id ? (
                                        <input 
                                            type="text" 
                                            value={editCategoryName} 
                                            onChange={(e) => setEditCategoryName(e.target.value)} 
                                            style={styles.editInput}
                                        />
                                    ) : (
                                        category.name
                                    )}
                                </td>
                                <td style={styles.tableCell}>
                                    {editCategoryId === category.id ? (
                                        <button style={styles.button} onClick={handleUpdateCategory}>Update</button>
                                    ) : (
                                        <button 
                                            style={styles.button} 
                                            onClick={() => handleEditCategory(category.id, category.name)}
                                        >
                                            Edit
                                        </button>
                                    )}
                                    <button 
                                        style={styles.button} 
                                        onClick={() => handleDeleteCategory(category.id)}
                                    >
                                        Hapus
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
    
                {/* Komponen Pagination */}
                <Pagination
                    articlesPerPage={categoriesPerPage}
                    totalArticles={categories.length}
                    paginate={paginate}
                    currentPage={currentPage}
                />
            </div>
            </div>
        </div>
    );    
};

const styles = {
    title: {
        fontSize: '24px',
        fontWeight: 'bold',
        marginBottom: '-60px',
        marginTop: '100px',
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
      },
    inputContainer: {
        marginTop: '100px',
            marginBottom: '20px',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            marginLeft: '3px', // Menambah margin kiri
    },
    label: {
        fontSize: '16px', // Sesuaikan ukuran font
        marginRight: '10px', // Tambahkan jarak setelah label
    },
    input: {
        padding: '10px', // Padding
        borderRadius: '5px',
        border: '1px solid #ccc',
        marginRight: '10px',
        flexGrow: 1, // Agar input mengisi ruang yang tersedia
        fontSize: '16px', // Sesuaikan ukuran font
        width: '70%', // Sesuaikan dengan lebar kolom tabel
    },
    editInput: { // Style for the input while editing
        padding: '10px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        width: '70%', // Adjust width to match
    },
    addButton: {
        backgroundColor: '#005694',
        color: 'white',
        border: 'none',
        padding: '10px 10px',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px', // Match font size with other buttons
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        marginTop: '20px',
    },
    tableHeader: {
        backgroundColor: '#f2f2f2',
        padding: '10px',
        border: '1px solid #ddd',
    },
    tableCell: {
        padding: '10px',
        border: '1px solid #ddd',
        textAlign: 'center',
        fontSize: '16px', // Sesuaikan ukuran font
    },
    button: {
        backgroundColor: '#005694',
        color: 'white',
        border: 'none',
        padding: '5px 10px',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '12px',
        margin: '0 5px',
    },
};

export default CategoriesList;
