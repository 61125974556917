import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import configUrl from '../configUrl';

const ArticleCard = ({ article, index, onEdit, handleDelete, onSetAsSlider, showControls }) => {
  const navigate = useNavigate();

  const handleSetAsSlider = async (articleId) => {
    try {
      const token = localStorage.getItem('authTokenSitusNews');
      if (!token) {
        navigate('/login');
        return;
      }

      const isSlider = article.slider;
      await axios.post(`${configUrl.beBaseUrl}/api/articles/${article.id}/${isSlider ? 'unset-slider' : 'set-as-slider'}`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (onSetAsSlider) {
        onSetAsSlider(article.id, !isSlider);
      }

      window.location.reload();

    } catch (error) {
      console.error('Error setting article as slider headline:', error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `Pada: ${day}-${month}-${year} / ${hours}:${minutes}`;
  };

  return (
    <div style={styles.popularArticleCard}>
      <img 
        src={`${configUrl.beBaseUrl}${article.image_url}`} // Assuming `image_url` is the correct field
        alt={article.title}
        style={styles.img}
      />
      <div style={styles.articleContent}>
        <Link to={`/articles/${article.id}/${encodeURIComponent(article.slug)}`} style={styles.articleLink}>
          {article.title}
        </Link>
        <p style={styles.articleBody}>
          {article.body.substring(0, 100)}...
        </p>
        <div style={styles.metaContainer}>
          <span style={styles.articleDate}>{formatDate(article.created_at)}</span>
          <span style={styles.popularArticleViews}>{article.views} views</span>
        </div>
      </div>

      {showControls && (
        <div style={styles.buttonGroup}>
          <button style={styles.button} onClick={() => onEdit(article.id)}>Ubah</button>
          <button style={styles.button} onClick={() => handleDelete(article.id)}>Hapus</button>
          <button style={styles.button} onClick={() => handleSetAsSlider(article.id)}>
            {article.slider ? 'Un-Set as HeadLine' : 'Set as HeadLine'}
          </button>
        </div>
      )}
    </div>
  );
};

// CSS in JS
const styles = {
  popularArticleCard: {
    display: 'flex',
    marginBottom: '10px',
    borderBottom: '1px solid #ddd',
    paddingBottom: '5px',
  },
  img: {
    width: '100px',
    height: '70px',
    objectFit: 'cover',
    marginRight: '20px',
  },
  articleContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  articleLink: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#005694',
    textDecoration: 'none',
    marginBottom: '-10px',
    textAlign: 'left',
  },
  articleBody: {
    fontSize: '15px',
    color: '#555',
    marginBottom: '8px',
    textAlign: 'left',
  },
  metaContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '12px',
    color: '#666',
  },
  buttonGroup: {
    display: 'flex',
    gap: '10px',
    marginTop: '10px',
  },
  button: {
    backgroundColor: '#5cb4f6',
    color: 'white',
    border: 'none',
    padding: '5px 10px',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '12px',
  },
};

export default ArticleCard;
