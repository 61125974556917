import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import configUrl from '../configUrl';

const VideoDetail = () => {
  const { id } = useParams(); // Ambil ID dari URL
  const [video, setVideo] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchVideoDetail = async () => {
    try {
      const response = await axios.get(`${configUrl.beBaseUrl}/api/articles/${id}`);
      setVideo(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching video detail:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchVideoDetail();
  }, [id]);

  if (loading) return <div>Loading video...</div>;
  if (!video) return <div>Video not found.</div>; // Tampilkan ini jika video tidak ditemukan

  return (
    <div>
      <h1>{video.title}</h1>
      <iframe
        src={video.video_url} // Ganti dengan properti yang sesuai
        title={video.title}
        width="100%"
        height="500"
        frameBorder="0"
        allowFullScreen
      ></iframe>
      <p>{video.description}</p>
    </div>
  );
};

export default VideoDetail;
