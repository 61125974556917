import configUrl from '../configUrl';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { FaEdit, FaFolder, FaPlus, FaUser, FaChartBar, FaHome } from 'react-icons/fa';
import { Link } from 'react-router-dom';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ChartArticlesPopular = () => {
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [categoriesCount, setCategoriesCount] = useState(0);
    const [articlesCount, setArticlesCount] = useState(0);
    const [usersCount, setUsersCount] = useState(0);

    useEffect(() => {
        const fetchPopularArticles = async () => {
            try {
                const response = await axios.get(`${configUrl.beBaseUrl}/api/articlespop`);
                setArticles(response.data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchPopularArticles();
    }, []);

    useEffect(() => {
        const fetchCategoriesCount = async () => {
            try {
                const response = await axios.get(`${configUrl.beBaseUrl}/api/categories`);
                setCategoriesCount(response.data.length);
            } catch (err) {
                console.error(err);
            }
        };

        fetchCategoriesCount();
    }, []);

    useEffect(() => {
        const fetchArticlesCount = async () => {
            try {
                const response = await axios.get(`${configUrl.beBaseUrl}/api/articles`);
                setArticlesCount(response.data.length);
            } catch (err) {
                console.error(err);
            }
        };

        fetchArticlesCount();
    }, []);

    // useEffect(() => {
    //     const fetchUsersCount = async () => {
    //         try {
    //             const response = await axios.get(`${configUrl.beBaseUrl}/api/users`);
    //             setUsersCount(response.data.length);
    //         } catch (err) {
    //             console.error(err);
    //         }
    //     };

    //     fetchUsersCount();
    // }, []);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    const labels = articles.map(article =>
        article.title.length > 16 ? article.title.slice(0, 16) + '...' : article.title
    );
    const data = articles.map(article => article.views);

    const chartData = {
        labels,
        datasets: [
            {
                label: 'Views',
                data,
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
        ],
    };

    const MenuDashboard = () => (
        <div>
            <h2 style={styles.title}>
                <FaHome style={styles.icon} /> Dashboard
            </h2>
            <div style={styles.menuContainer}>
                <Link to="/category" style={styles.menuItem}>
                    <div style={styles.iconContainer}>
                        <FaFolder style={styles.menuIcon} />
                    </div>
                    <div>
                        <h3>Categories</h3>
                        <p>{categoriesCount}</p>
                    </div>
                </Link>
                <Link to="/create-article" style={styles.menuItem}>
                    <div style={styles.iconContainer}>
                        <FaPlus style={styles.menuIcon} />
                    </div>
                    <div>
                        <h3>Create</h3>
                        <p></p>
                    </div>
                </Link>
                <Link to="/users" style={styles.menuItem}>
                    <div style={styles.iconContainer}>
                        <FaUser style={styles.menuIcon} />
                    </div>
                    <div>
                        <h3>User</h3>
                        <p>{usersCount > 0 ? usersCount : 0}</p>
                    </div>
                </Link>
                <Link to="/dashboard" style={styles.menuItem}>
                    <div style={styles.iconContainer}>
                        <FaEdit style={styles.menuIcon} />
                    </div>
                    <div>
                        <h3>Post</h3>
                        <p>{articlesCount}</p>
                    </div>
                </Link>
            </div>
        </div>
    );

    const styles = {
        title: {
            fontSize: '24px',
            marginBottom: '-120px',
            marginTop: '90px',
            textAlign: 'left',
            padding: '30px',
        },
        menuContainer: {
            display: 'flex',
            justifyContent: 'space-around',
            padding: '80px',
            backgroundColor: '#fff',
            marginBottom: '-30px',
            marginTop: '50px',
        },
        menuItem: {
            display: 'flex',
            alignItems: 'center',
            padding: '20px',
            backgroundColor: '#fff',
            borderRadius: '10px',
            boxShadow: '0px 2px 5px rgba(0,0,0,0.1)',
            width: '150px',
            justifyContent: 'space-between',
            textDecoration: 'none',
            color: '#000',
        },
        iconContainer: {
            backgroundColor: '#005694',
            borderRadius: '10px',
            padding: '10px',
        },
        menuIcon: {
            fontSize: '30px',
            color: '#fff',
        },
        chartContainer: {
            border: '1px solid #ddd',
            borderRadius: '10px',
            padding: '20px',
            boxShadow: '0px 2px 5px rgba(0,0,0,0.1)',
            marginTop: '20px',
            maxWidth: '830px',
            margin: '0 auto',
        },
        chartTitle: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: '20px',
        },
        titleIcon: {
            fontSize: '24px',
            marginRight: '10px',
            color: '#005694',
        },
        titleText: {
            fontSize: '20px',
            fontWeight: 'bold',
        },
        titleLine: {
            width: '100%',
            height: '2px',
            backgroundColor: '#005694',
            marginTop: '5px',
            marginBottom: '15px',
        },
        chartOptions: {
            responsive: true,
            maintainAspectRatio: false,
        },
        chartHeight: {
            height: '300px',
        },
    };

    return (
        <div>
            <MenuDashboard />
            <div style={styles.chartContainer}>
                <div style={styles.chartTitle}>
                    <FaChartBar style={styles.titleIcon} />
                    <span style={styles.titleText}>Statistics Views</span>
                </div>
                <div style={styles.titleLine} />
                <div style={styles.chartHeight}>
                    <Bar data={chartData} options={styles.chartOptions} />
                </div>
            </div>
        </div>
    );
};

export default ChartArticlesPopular;
