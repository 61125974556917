import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import configUrl from '../configUrl';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../utils/AuthContext';

const GoogleLoginButton = () => {
    const navigate = useNavigate();
    const { login } = useAuth();

    const handleLoginSuccess = async (credentialResponse) => {
        try {
            const response = await axios.post(`${configUrl.beBaseUrl}/api/auth/google`, {
                token: credentialResponse.credential,
            });

            const { token, user } = response.data;

            let emailEksis =  user.email;
            if (emailEksis == "kartikalidya032@gmail.com") {
                sessionStorage.setItem('authTokenSitusNews', token);
                sessionStorage.setItem('authorId', JSON.stringify(user));
    
                localStorage.setItem('authTokenSitusNews', token);
                localStorage.setItem('authorId', JSON.stringify(user));

                login();
                navigate('/index');
            } else {
                alert('email ini tidak terdaftar');
                navigate('/login');
            }

        } catch (error) {
            // console.log('sampai sini4');
            if (error.response) {
                const { status } = error.response;

                if (status === 500) {
                    alert('Terjadi kesalahan pada server. Silakan coba lagi nanti.');
                } else if (status === 401) {
                    alert('Email ini tidak dapat digunakan untuk login.');
                } else {
                    alert(`Terjadi masalah saat login (Kode: ${status}). Silakan coba lagi.`);
                }
            } else {
                alert('Tidak dapat terhubung ke server. Periksa koneksi Anda.');
            }
            console.error('Detail Error:', error);
        }
    };

    return (
        <GoogleOAuthProvider clientId="1002650215948-tu7itm4ho8pjft3jgivqmrp10187337g.apps.googleusercontent.com">
            <GoogleLogin
                onSuccess={handleLoginSuccess}
                onError={() => alert('Login gagal. Silakan coba lagi.')}
            />
        </GoogleOAuthProvider>
    );
};

export default GoogleLoginButton;
