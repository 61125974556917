import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const PrivacyPolicy = () => {
  return (
    <div>
      <Header />
    <div style={styles.container}>
      <h1>Kebijakan Data Pribadi</h1>
      <h2>KG Media</h2>
      <p>
        Kompas Gramedia Group of Media atau dikenal dengan nama KG Media merupakan lini usaha yang berada dalam Kompas Gramedia Group dan perseroan lain yang terkait secara kepemilikan dengan Kompas Gramedia Group.
      </p>
      <p>
        KG Media berkomitmen untuk melindungi data pribadi pelanggan, pengunjung, vendor, mitra, dan banyak pemilik kepentingan terkait lainnya. Oleh karena itu, dalam Kebijakan Data Pribadi ini, Kami mempertimbangkan aspek penerapan teknologi, sumber daya manusia, metode dan mengacu pada hukum yang berlaku di Indonesia, seperti Peraturan Menteri dan peraturan perundang-undangan lainnya yang terkait.
      </p>
      <h3>A. DEFINISI</h3>
      <p>
        KG Media/Kami berarti Kompas Gramedia Group of Media yang bergerak di berbagai bidang bisnis media, seperti Televisi, Radio, Percetakan, Digital dan bisnis area media lainnya.
      </p>
      <p>
        Afiliasi berarti anak usaha dari dan/atau pihak di bawah kendali Kompas Gramedia Group of Media dan/atau Kompas Gramedia Group.
      </p>
      <p>
        Layanan/layanan berarti setiap jasa dan/atau produk dan/atau kegiatan maupun layanan lainnya dalam bentuk apapun yang Kami berikan/selenggarakan baik yang Kami berikan/selenggarakan melalui Portal maupun tanpa melalui Portal (seperti event dan sebagainya).
      </p>
      <p>
        Portal berarti setiap aplikasi perangkat lunak dan/atau setiap situs-web dan/atau setiap bentuk platform digital lainnya baik yang sudah ada maupun akan ada di masa yang akan mendatang, yang Kami dan/atau Afiliasi Kami kembangkan dan operasikan sehingga Layanan Kami dapat diakses oleh Anda.
      </p>
      <p>
        Pelanggan/Pengguna/Anda/Diri Sendiri adalah setiap pihak yang menggunakan dan/atau mengakses dan/atau turut serta pada Layanan Kami baik melalui Portal maupun tidak melalui Portal, serta baik yang sudah terdaftar pada Portal maupun yang belum terdaftar dalam Portal.
      </p>
      <p>
        Data Pribadi/data berarti data atau informasi mengenai diri Anda yang Kami peroleh melalui penggunaan/interaksi Portal dan/atau layanan oleh Anda baik yang dapat diidentifikasi secara pribadi maupun yang belum diidentifikasi secara pribadi termasuk namun tidak terbatas seperti nama, alamat, tanggal lahir, tempat kelahiran, pekerjaan, tempat bekerja, identitas pribadi, dokumen identitas lainnya, nomor telepon, alamat surel, dan informasi lainnya yang dapat mengidentifikasi Anda sebagai pengguna.
      </p>
      <h3>B. RUANG LINGKUP</h3>
      <p>
        Kebijakan Data Pribadi ini berlaku untuk semua Data Pribadi di seluruh Layanan Kami dan/atau mitra bisnis Kami (vendor, mitra, dan pemangku kepentingan lainnya).
      </p>
      <p>
        Mohon lihat berbagai produk dan anak usaha yang merupakan Afiliasi dari Kami di sini:
        <a href="https://www.kompasgramedia.com/business/media" target="_blank" rel="noopener noreferrer"> KG Media</a>
      </p>
      <p>
        Setiap penambahan suatu produk/layanan yang akan datang di kemudian hari merupakan produk/ layanan yang diakui oleh Kami, maka secara serta-merta mengikuti Kebijakan Data Pribadi ini.
      </p>
      <h3>C. TUJUAN</h3>
      <p>
        Kami mengumpulkan, mengolah, menggunakan dan memberikan Data Pribadi Anda termasuk kepada Afiliasi dan/atau pihak ketiga/mitra Kami, untuk tujuan berikut:
      </p>
      <ul>
        <li>Untuk memberikan dukungan atau informasi lainnya;</li>
        <li>Untuk memberikan informasi mengenai produk atau informasi terkini;</li>
        <li>Menganalisis perilaku Pelanggan untuk memberikan pengalaman, pelayanan dan informasi yang lebih baik;</li>
        <li>Untuk menginformasikan manfaat, program, dan kesempatan yang Kami berikan kepada Anda;</li>
        <li>Untuk berhubungan dengan pihak ketiga/mitra Kami;</li>
        <li>Untuk melindungi konten dan Layanan Kami;</li>
        <li>Untuk mendapatkan saran dan masukan dari Anda dari waktu ke waktu;</li>
      </ul>
      <h3>D. DATA YANG KAMI KUMPULKAN</h3>
      <p>
        Kami mengumpulkan beberapa Data Pribadi yang secara langsung disediakan oleh Anda sebagai berikut:
      </p>
      <ul>
        <li>Nama</li>
        <li>Informasi kontak (telepon, surel)</li>
        <li>Tanggal lahir</li>
        <li>Tempat lahir</li>
        <li>Demografi: jenis kelamin, pekerjaan</li>
        <li>Informasi identifikasi pribadi (KTP, SIM, Paspor, dan identifikasi pribadi lainnya)</li>
        <li>Informasi kontak darurat</li>
        <li>Informasi profil media sosial</li>
        <li>Informasi pendidikan dan pekerjaan</li>
        <li>Informasi lain dari Anda, yang diunggah atau diberikan kepada Kami.</li>
      </ul>
      <h3>E. DATA YANG TIDAK KAMI KUMPULKAN</h3>
      <p>
        Kami sangat menghargai kerahasian data Anda, oleh karena itu, Kami tidak mengumpulkan data-data berupa konten apapun dari album foto, komunikasi aplikasi pesan singkat (app messenger), dan informasi kontak/phonebook yang terkandung dalam perangkat anda.
      </p>
      <h3>F. KEAMANAN</h3>
      <p>
        Kerahasiaan informasi dan Data Pribadi Anda menjadi perhatian utama bagi Kami. Kami melakukan usaha dan prosedur terbaik untuk melindungi serta menjamin Data Pribadi dan Kami secara berkala melakukan evaluasi secara internal untuk meningkatkan keamanan sehingga Data Pribadi Anda aman dari gangguan pihak tanpa izin.
      </p>
      <h3>G. IKLAN</h3>
      <p>
        Iklan yang muncul pada Portal mungkin dikirim ke Anda oleh mitra kami, yang mungkin menerapkan cookies.
      </p>
      <h3>H. KETENTUAN TERHADAP ANAK DI BAWAH UMUR</h3>
      <p>
        Setiap pembuatan akun Portal dan/atau pendaftaran atas Layanan KG Media dan/atau Afiliasinya dilarang dilakukan oleh anak-anak di bawah usia 18 (delapan belas) tahun.
      </p>
      <h3>I. INFORMASI TEKNIS DAN PERATURAN</h3>
      <p>
        Data yang bersifat pribadi Pengguna: Kami mengumpulkan Data Pribadi dari Pengguna melalui berbagai cara, termasuk namun tidak terbatas pada, ketika Pengguna mengunjungi Portal, menggunakan fitur, mengakses konten yang kami sediakan dalam Portal, ketika mendaftar sebagai Pengguna, ketika Pengguna menggunakan layanan dan sebagainya. Pengguna juga dapat diminta memberikan data, antara lain, alamat surel, alamat rumah/kantor, nomor telepon dan informasi lainnya yang dibutuhkan.
      </p>
      <p>
        Data yang bersifat non-pribadi Pengguna: Kami dapat mengumpulkan data non-pribadi Pengguna ketika mereka berinteraksi dengan Portal dan/atau layanan Kami. Setiap data non-personal termasuk namun tidak terbatas pada nama browser, tipe computer, perangkat telepon, atau informasi lainnya berkaitan dengan cara Pengguna terhubung dengan Portal kami, seperti sistem operasi, layanan internet, sosial media, dan informasi terkait lainnya.
      </p>
      <p>
        Dalam hal ketika Anda memilih untuk menautkan akun media sosial atau akun Anda yang terkait dengan layanan pihak ketiga lainnya ke Layanan Kami dan/atau Portal, maka Anda mungkin meminta pihak ketiga untuk mengirimkan informasi tertentu kepada Kami dari akun media sosial atau akun layanan tersebut dan Anda mengijinkan Kami untuk mengumpulkan, menyimpan, dan menggunakan apa yang mereka kirimkan kepada Kami sesuai dengan yang dijelaskan dalam Kebijakan Data Pribadi ini.
      </p>
      <p>
        Logging Practices:
      </p>
      <p>
        Kami secara otomatis merekam alamat Internet Protocol (IP) dari Pengguna (web based application) dan sesi dari pengguna (mobile application). Kami juga merekam beberapa material-material untuk mengidentifikasi pengguna atas Portal, termasuk:
      </p>
      <ul>
        <li>Tipe/versi dari browser/perangkat</li>
        <li>Sistem operasi yang digunakan</li>
        <li>Tanggal dan waktu dari server request</li>
        <li>Lokasi (lintang dan bujur)</li>
      </ul>
      <p>
        Untuk aplikasi seluler, kami menggunakan bahan-bahan berikut untuk mengidentifikasi:
      </p>
      <ul>
        <li>Brand dan/atau tipe/versi perangkat</li>
        <li>Perangkat IMEI</li>
        <li>Sistem operasi yang digunakan</li>
        <li>Tanggal dan waktu dari server request</li>
        <li>Lokasi (lintang dan bujur)</li>
        <li>Informasi lainnya yang disediakan oleh sistem analitik kami</li>
      </ul>
      <h3>J. PERUBAHAN KEBIJAKAN DATA PRIBADI</h3>
      <p>
        Kami dapat mengubah atau memperbarui Kebijakan Data Pribadi ini dari waktu ke waktu. Segala perubahan pada Kebijakan Data Pribadi ini akan dipublikasikan di Portal. Kami mendorong Anda untuk memeriksa Kebijakan Data Pribadi ini secara berkala untuk memastikan bahwa Anda tetap setuju dengan kebijakan yang berlaku. Kebijakan Data Pribadi yang berlaku adalah yang dipublikasikan dalam Portal pada saat Anda menggunakan layanan Kami.
      </p>
      <h3>K. PERTANYAAN DAN PENGADUAN</h3>
      <p>
        Jika Anda memiliki pertanyaan atau pengaduan terkait Kebijakan Data Pribadi ini, silakan hubungi Kami di:
        <br />
        <a href="mailto:privacy@kgmedia.com">privacy@kgmedia.com</a>
      </p>
      <p>Terima kasih atas perhatian dan kepercayaan Anda kepada KG Media.</p>
    </div>
    <Footer />
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    maxWidth: '800px',
    margin: '0 auto',
    fontFamily: 'Arial, sans-serif',
    textAlign: 'left',
  },
};

export default PrivacyPolicy;
