import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../utils/AuthContext';
import CategoryDropdown from './CategoryDropdown';
import { FaSearch, FaBars, FaTimes } from 'react-icons/fa';

const Header = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const [tampilkanDropdown, setTampilkanDropdown] = useState(false);
  const [tampilkanMenuDinamis, setTampilkanMenuDinamis] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Tambahkan state untuk mode mobile
  
  const toggleDropdown = () => {
    setTampilkanDropdown((prevState) => !prevState);
  };

  const goToSearchPage = () => {
    navigate('/search');
  };

  // Handle scroll and resize events
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100 && !isMobile) {
        setTampilkanMenuDinamis(true);
      } else {
        setTampilkanMenuDinamis(false);
      }

      setIsSticky(window.scrollY > 0);
    };

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      if (window.innerWidth <= 768) {
        setTampilkanMenuDinamis(false); // Hilangkan menuDinamis di layar kecil
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile]);

  return (
    <div>
      <header
        style={{
          ...styles.header,
          ...(isSticky ? styles.stickyHeader : {}),
        }}
      >
        <div style={styles.logoContainer}>
          <a href="https://www.kartikalidya.com">
            <img src="/image/kartikanews-logo.png" alt="kartikanews-logo" style={styles.logoImage} />
          </a>
        </div>

        {/* Menu dinamis hanya ditampilkan jika tidak dalam mode mobile */}
        {tampilkanMenuDinamis && !isMobile && (
          <div id="menuDinamis" style={styles.dynamicMenu}>
            <Link to="/" style={styles.dynamicMenuLink}>Beranda</Link>
            <Link to="/BeritaTerkini" style={styles.dynamicMenuLink}>Terkini</Link>
            <Link to="/PopularArticles" style={styles.dynamicMenuLink}>Terpopuler</Link>
            <Link to="/kategori/video" style={styles.dynamicMenuLink}>Video</Link>
            <Link to="/kategori/news" style={styles.dynamicMenuLink}>News</Link>
            <Link to="/kategori/bisnis" style={styles.dynamicMenuLink}>Bisnis</Link>
            <Link to="/kategori/seleb" style={styles.dynamicMenuLink}>Seleb</Link>
            <Link to="/kategori/karir" style={styles.dynamicMenuLink}>Karir</Link>
            <Link to="/kategori/ekonomi" style={styles.dynamicMenuLink}>Ekonomi</Link>
            <Link to="/kategori/religi" style={styles.dynamicMenuLink}>Religi</Link>
            <Link to="/kategori/otomotif" style={styles.dynamicMenuLink}>Otomotif</Link>
            <Link to="/kategori/musik" style={styles.dynamicMenuLink}>Musik</Link>
            </div>
        )}

        <div style={styles.iconsContainer}>
          <div style={{ ...styles.icon, ...styles.searchIcon }} onClick={goToSearchPage}>
            <FaSearch />
          </div>
          <div style={{ ...styles.icon, ...styles.hamburgerMenu }} onClick={toggleDropdown}>
            {tampilkanDropdown ? <FaTimes /> : <FaBars />}
          </div>
        </div>
        {tampilkanDropdown && (
          <div style={styles.dropdownContainer}>
            <CategoryDropdown />
          </div>
        )}
      </header>

      <nav style={styles.navbar}>
        <div style={styles.navContent}>
          <Link to="/" style={styles.navLink}>Beranda</Link>
          <Link to="/BeritaTerkini" style={styles.navLink}>Terkini</Link>
          <Link to="/PopularArticles" style={styles.navLink}>Terpopuler</Link>
          <Link to="/kategori/video" style={styles.navLink}>Video</Link>
          <Link to="/kategori/news" style={styles.navLink}>News</Link>
          <Link to="/kategori/bisnis" style={styles.navLink}>Bisnis</Link>
          <Link to="/kategori/seleb" style={styles.navLink}>Seleb</Link>
          <Link to="/kategori/karir" style={styles.navLink}>Karir</Link>
          <Link to="/kategori/ekonomi" style={styles.navLink}>Ekonomi</Link>
          <Link to="/kategori/religi" style={styles.navLink}>Religi</Link>
          <Link to="/kategori/otomotif" style={styles.navLink}>Otomotif</Link>
          <Link to="/kategori/kesehatan" style={styles.navLink}>Kesehatan</Link>
          <Link to="/kategori/musik" style={styles.navLink}>Musik</Link>
        </div>
      </nav>
    </div>
  );
};

const styles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: '10px 0px',
    position: 'relative',
    transition: 'all 0.3s ease',
    zIndex: 1000,
  },
  stickyHeader: {
    position: 'fixed',
    top: 0,
    width: '100%',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
    marginLeft: '20px',
  },
  logoImage: {
    maxHeight: '40px',
  },
  iconsContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '18px',
  },
  icon: {
    fontSize: '24px',
    color: '#005694',
    marginLeft: '20px',
    cursor: 'pointer',
  },
  searchIcon: {
    position: 'relative',
    marginRight: '5px',
  },
  hamburgerMenu: {
    position: 'relative',
    marginRight: '20px',
  },
  dropdownContainer: {
    position: 'absolute',
    right: '20px',
    top: '60px',
    zIndex: 1000,
  },
  navbar: {
    display: 'flex',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    backgroundColor: '#005694',
    padding: '10px 0',
    marginTop: '10px',
  },
  navContent: {
    display: 'inline-flex',
  },
  navLink: {
    color: 'white',
    textDecoration: 'none',
    padding: '10px 30px',
    fontWeight: 'bold',
  },
  dynamicMenu: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '0',
    margin: '0 auto',
  },
  dynamicMenuLink: {
    color: '#005694',
    textDecoration: 'none',
    padding: '0 10px',
    fontWeight: 'bold',
  },
};

export default Header;

