import React from 'react';
import { Link } from 'react-router-dom';
import { FaYoutube, FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer style={styles.footer}>
      <div style={styles.overlay}>
        <div style={styles.container}>
          <div style={styles.about}>
            <h2 style={{ textAlign: 'left', color: '#005694' }}>About Us</h2>
            <div style={styles.listContainer}>
              <div style={styles.listColumn}>
                <ul style={styles.list}>
                  <li><Link to="/about" style={{ textDecoration: 'none', color: '#005694' }}>Tentang kami</Link></li>
                  <li><Link to="/Help" style={{ textDecoration: 'none', color: '#005694' }}>Help</Link></li>
                  <li><Link to="/TermsOfUse" style={{ textDecoration: 'none', color: '#005694' }}>Terms of Use</Link></li>
                </ul>
              </div>
              <div style={styles.listColumn}>
                <ul style={styles.list}>
                <li><Link to="/PedomanMediaSiber" style={{ textDecoration: 'none', color: '#005694' }}>Pedoman Media Siber</Link></li>
                  <li><Link to="/contact" style={{ textDecoration: 'none', color: '#005694' }}>Hubungi kami</Link></li>
                  <li><Link to="/PrivacyPolicy" style={{ textDecoration: 'none', color: '#005694' }}>Privacy Policy</Link></li>
                </ul>
              </div>
            </div>
          </div>
          <div style={styles.logo}>
            <img src="/image/kartikanews-logo.png" alt="kartikanews-logo" style={styles.logoImage} />
          </div>
          <div style={styles.socialMedia}>
            <a href="https://youtube.com/@kartikapark9441?si=_ofE7ivFVfH_6mYs" style={styles.socialLink}><FaYoutube /></a>
            <a href="https://www.facebook.com/profile.php?id=61559686590723" style={styles.socialLink}><FaFacebook /></a>
            <a href="https://www.instagram.com/onyourkar__tika?igsh=MXM3bDhtN21yaTZwcQ==" style={styles.socialLink}><FaInstagram /></a>
            <a href="https://x.com/yayayaa12552216" style={styles.socialLink}><FaTwitter /></a>
          </div>
        </div>
      </div>
      <div style={styles.copyright}>
        <p>&copy; 2024 kartikalidya. All Rights Reserved</p>
      </div>
    </footer>
  );
}

const styles = {
  footer: {
    backgroundImage: 'url("https://www.kartikalidya.com/image/bg-footer.png")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: '1px 0', // Added bottom padding to ensure space for copyright section
    textAlign: 'center',
    borderTop: '1px solid #e7e7e7',
    position: 'relative', // Position relative to keep footer content separate from copyright
  },
  overlay: {
    backgroundColor: 'transparent', // Fixed typo (transparent instead of 'transparan')
    padding: '20px 0',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  about: {
    textAlign: 'left',
  },
  listContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  listColumn: {
    flex: 2,
    marginRight: '10px',
  },
  list: {
    listStyleType: 'none',
    padding: 0,
  },
  logo: {
    margin: '50px 10px',
    justifyContent: 'center',
  },
  logoImage: {
    maxHeight: '78px', // Reduced size for better alignment
    justifyContent: 'center',
  },
  socialMedia: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '200px',
  },
  socialLink: {
    color: '#005694',
    fontSize: '24px',
    textDecoration: 'none',
    marginRight: '10px',
  },
  copyright: {
    backgroundColor: '#005694',
    color: '#ffffff',
    padding: '10px 0',
    marginTop: '20px', // Added margin to push it down below the footer content
    position: 'absolute',
    bottom: -70,
    width: '100%',
  },
};

export default Footer;