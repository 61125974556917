import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import DOMPurify from 'dompurify'; // Import DOMPurify

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      city: '',
      address: '',
      relatedLink: '',
      message: '',
      newsType: '',
      errors: {},
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleRadioChange = (e) => {
    this.setState({ newsType: e.target.value });
  };

  // Validate and sanitize form
  validateForm = () => {
    const { name, email, city, message, newsType } = this.state;
    const errors = {};

    if (!name) errors.name = 'Nama wajib diisi!';
    if (!email || !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      errors.email = 'Email tidak valid!';
    }
    if (!city) errors.city = 'Kota wajib diisi!';
    if (!message) errors.message = 'Pesan wajib diisi!';
    if (!newsType) errors.newsType = 'Silakan pilih jenis berita.';

    this.setState({ errors });
    return Object.keys(errors).length === 0;
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (!this.validateForm()) {
      alert('Harap lengkapi semua kolom dengan benar.');
      return;
    }

    // Sanitize the input values to prevent XSS or unwanted symbols
    const { name, email, city, address, relatedLink, message, newsType } = this.state;
    
    const sanitizedMessage = DOMPurify.sanitize(message); // Sanitize message
    const sanitizedName = DOMPurify.sanitize(name); // Sanitize name
    const sanitizedEmail = DOMPurify.sanitize(email); // Sanitize email
    const sanitizedCity = DOMPurify.sanitize(city); // Sanitize city
    const sanitizedAddress = DOMPurify.sanitize(address); // Sanitize address
    const sanitizedRelatedLink = DOMPurify.sanitize(relatedLink); // Sanitize relatedLink

    const mailtoLink = `mailto:kartika@kartikalidya.com?subject=Contact from ${sanitizedName}&body=Name: ${sanitizedName}%0AEmail: ${sanitizedEmail}%0ACity: ${sanitizedCity}%0AAddress: ${sanitizedAddress}%0ANews Type: ${newsType}%0ARelated Link: ${sanitizedRelatedLink}%0AMessage: ${sanitizedMessage}`;

    alert('Terima kasih! Pesan Anda telah dikirim.');

    this.setState(
      {
        name: '',
        email: '',
        city: '',
        address: '',
        relatedLink: '',
        message: '',
        newsType: '',
        errors: {},
      },
      () => {
        window.location.href = mailtoLink;
      }
    );
  };

  renderError = (field) => {
    const { errors } = this.state;
    return errors[field] ? <p style={styles.error}>{errors[field]}</p> : null;
  };

  render() {
    return (
      <div>
        <Header />
        <div style={styles.contactForm}>
          <h1 style={styles.header}>Hubungi Kami</h1>
          <p style={styles.paragraph}>
            Ajukan pertanyaan, kritik, dan saran tentang kartikalidya.com
          </p>
          <form onSubmit={this.handleSubmit}>
            <input
              type="text"
              name="name"
              placeholder="Nama"
              style={styles.input}
              value={this.state.name}
              onChange={this.handleChange}
            />
            {this.renderError('name')}

            <input
              type="email"
              name="email"
              placeholder="Email"
              style={styles.input}
              value={this.state.email}
              onChange={this.handleChange}
            />
            {this.renderError('email')}

            <input
              type="text"
              name="city"
              placeholder="Kota"
              style={styles.input}
              value={this.state.city}
              onChange={this.handleChange}
            />
            {this.renderError('city')}

            <input
              type="text"
              name="address"
              placeholder="Alamat"
              style={styles.input}
              value={this.state.address}
              onChange={this.handleChange}
            />

            <p style={styles.paragraph}>Berkaitan dengan?</p>
            <div style={styles.radioButtons}>
              {['Berita', 'Iklan', 'Digital', 'Lainnya'].map((type) => (
                <div key={type} style={styles.radioGroup}>
                  <input
                    type="radio"
                    id={`newsType-${type}`}
                    name="newsType"
                    value={type}
                    checked={this.state.newsType === type}
                    onChange={this.handleRadioChange}
                  />
                  <label htmlFor={`newsType-${type}`} style={styles.label}>
                    {type}
                  </label>
                </div>
              ))}
            </div>
            {this.renderError('newsType')}

            <input
              type="text"
              name="relatedLink"
              placeholder="Tautan terkait"
              style={styles.input}
              value={this.state.relatedLink}
              onChange={this.handleChange}
            />

            <textarea
              name="message"
              placeholder="Pesan"
              style={styles.textarea}
              value={this.state.message}
              onChange={this.handleChange}
            ></textarea>
            {this.renderError('message')}

            <button type="submit" style={styles.button}>Kirim</button>
          </form>
        </div>
        <Footer />
      </div>
    );
  }
}

const styles = {
  contactForm: {
    maxWidth: '500px',
    margin: 'auto',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
  },
  header: {
    textAlign: 'left',
    color: '#005694',
    marginBottom: '10px',
  },
  paragraph: {
    textAlign: 'left',
    fontWeight: '500',
    marginBottom: '10px',
  },
  input: {
    width: '94%',
    padding: '10px',
    margin: '10px 0',
    borderRadius: '10px',
    border: '1px solid #ccc',
  },
  radioButtons: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '10px',
  },
  radioGroup: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '10px',
  },
  label: {
    marginLeft: '5px',
  },
  textarea: {
    width: '94%',
    padding: '10px',
    margin: '10px 0',
    borderRadius: '10px',
    border: '1px solid #ccc',
  },
  button: {
    width: '100%',
    padding: '10px',
    backgroundColor: '#005694',
    borderRadius: '10px',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    fontSize: '16px',
  },
  contactInfo: {
    marginTop: '10px',
    textAlign: 'left',
    fontWeight: '500',
  },
  error: {
    color: 'red',
    fontSize: '12px',
    marginTop: '-8px',
  },
};

export default Contact;
